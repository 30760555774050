export default [
  {
    path: 'equipment',
    name: 'equipment',
    component: () => import('@/views/Base/Base'),
    meta: {
      title: 'lang.cpq.menu.equipmet',
      icon: 'tools', // 物料维护
    },
    children: [
      // 价格代办
      {
        path: 'price-to-do',
        name: 'priceTodo',
        component: () => import('@/views/Equipment/Price/List.vue'),
        meta: {
          title: 'lang.cpq.menu.equipmet.price.to.do.list',
          breadcrumb: [{ title: 'lang.cpq.menu.equipmet', path: '/equipment/price-to-do' }, { title: 'lang.cpq.menu.equipmet.price.to.do.list' }],
        },
      },
      // 待办列表
      {
        path: 'to-do',
        name: 'equipmentToDo',
        component: () => import('@/views/Equipment/Todo/List.vue'),
        meta: {
          title: 'lang.cpq.menu.equipmet.to.do.list',
          breadcrumb: [{ title: 'lang.cpq.menu.equipmet', path: '/equipment/to-do' }, { title: 'lang.cpq.menu.equipmet.to.do.list' }],
        },
      },
      // 物料信息
      {
        path: 'information',
        name: 'equipmentInformation',
        component: () => import('@/views/Equipment/Information/List.vue'),
        meta: {
          title: 'lang.cpq.menu.equipmet.information', // 物料库
          breadcrumb: [{ title: 'lang.cpq.menu.equipmet', path: '/equipment/information' }, { title: 'lang.cpq.menu.equipmet.information' }],
        },
      },
      // 物料信息
      {
        path: 'eu',
        name: 'equipmentEu',
        component: () => import('@/views/Equipment/EU/List.vue'),
        meta: {
          title: 'lang.cpq.label.euMaterial', // EU物料
          breadcrumb: [{ title: 'lang.cpq.menu.equipmet', path: '/equipment/eu' }, { title: 'lang.cpq.label.euMaterial' }],
        },
      },
      // 物料维护
      {
        path: 'maintain',
        name: 'equipmentMaintain',
        component: () => import('@/views/Equipment/Maintain/List.vue'),
        meta: {
          title: 'lang.cpq.menu.equipmet', // 物料维护
          breadcrumb: [{ title: 'lang.cpq.menu.equipmet', path: '/equipment/maintain' }, { title: 'lang.cpq.menu.equipmet' }],
        },
      },
      // 价格维护
      {
        path: 'price-information',
        name: 'equipmentPrice',
        component: () => import('@/views/Equipment/Price/List.vue'),
        meta: {
          title: 'lang.cpq.menu.equipmet.price.maintenance', // 价格维护
          breadcrumb: [{ title: 'lang.cpq.menu.equipmet', path: '/equipment/price-information' }, { title: 'lang.cpq.menu.equipmet.price.maintenance' }],
        },
      },
      // 分类配置
      {
        path: 'category',
        name: 'equipmentCategory',
        component: () => import('@/views/Equipment/Category/List.vue'),
        meta: {
          title: 'lang.cpq.menu.equipmet.classificationConfiguration', // 分类配置
          breadcrumb: [{ title: 'lang.cpq.menu.equipmet', path: '/equipment/category' }, { title: 'lang.cpq.menu.equipmet.classificationConfiguration' }],
        },
      },
      // erp异常物料
      {
        path: 'erp-exception',
        name: 'equipmentErpException',
        component: () => import('@/views/Equipment/ErpException/List.vue'),
        meta: {
          title: 'lang.cpq.menu.equipmet.abnormal.erp', //
          breadcrumb: [{ title: 'lang.cpq.menu.equipmet', path: '/equipment/erp-exception' }, { title: 'lang.cpq.menu.equipmet.abnormal.erp' }],
        },
      },
      // 渠道价格维护
      {
        path: 'channel-price-maintain',
        name: 'channelPrice',
        component: () => import('@/views/Equipment/ChannelPrice/List.vue'),
        meta: {
          title: 'lang.cpq.menu.equipmet.channel.price.maintain', //
          breadcrumb: [{ title: 'lang.cpq.menu.equipmet', path: '/equipment/channel-price-maintain' }, { title: 'lang.cpq.menu.equipmet.channel.price.maintain' }],
        },
      },
      // 渠道价格待办
      {
        path: 'channel-price-maintain-to-do',
        name: 'channelPriceTodo',
        component: () => import('@/views/Equipment/ChannelPrice/TodoList.vue'),
        meta: {
          title: 'lang.cpq.menu.equipmet.channel.price.maintain.to.do', //
          breadcrumb: [{ title: 'lang.cpq.menu.equipmet', path: '/equipment/channel-price-maintain-to-do' }, { title: 'lang.cpq.menu.equipmet.channel.price.maintain.to.do' }],
        },
      },
      // 价格表权限维护
      {
        path: 'price-permission',
        name: 'pricePermission',
        component: () => import('@/views/Equipment/PricePermission/List.vue'),
        meta: {
          title: 'lang.cpq.menu.equipment.price.permission',
          breadcrumb: [{ title: 'lang.cpq.menu.equipmet', path: '/equipment/price-permission' }, { title: 'lang.cpq.menu.equipment.price.permission' }],
        },
      },
      // 物料模板
      {
        path: 'order-template',
        name: 'orderTemplateList',
        component: () => import('@/views/Equipment/Template/List.vue'),
        meta: {
          title: 'lang.cpq.menu.equipment.order.template',
          breadcrumb: [{ title: 'lang.cpq.menu.equipmet', path: '/equipment/order-template' }, { title: 'lang.cpq.menu.equipment.order.template' }],
        },
      },
      // 新增-物料模板
      {
        path: 'order-template-create',
        name: 'createTemplate',
        component: () => import('@/views/Equipment/Template/Create.vue'),
        meta: {
          title: 'lang.cpq.menu.equipment.order.template.create',
          breadcrumb: [{ title: 'lang.cpq.menu.equipment.template', path: '/equipment/order-template' }, { title: 'lang.cpq.menu.equipment.order.template.create' }],
        },
      },

      // 虚拟物料列表
      {
        name: 'virtualMaterialList',
        path: '/equipment/virtual-material',
        component: () => import('@/views/Equipment/Virtualmaterial/List.vue'),
        meta: {
          title: 'lang.cpq.menu.virtual.material', // 虚拟物料列表
          breadcrumb: [{ title: 'lang.cpq.menu.virtual.material', path: '/equipment/virtual-material' }],
        },
      },
      // 虚拟物料申请
      {
        name: 'materialrequisition',
        path: '/equipment/material-requisition',
        component: () => import('@/views/Equipment/Virtualmaterial/Create.vue'),
        meta: {
          title: 'lang.cpq.menu.virtualMaterialApply', // 虚拟物料申请
          breadcrumb: [{ title: 'lang.cpq.menu.virtual.material', path: '/equipment/virtual-material' }, { title: 'lang.cpq.menu.virtualMaterialApply' }],
        },
      },
      // 虚拟物料查看
      {
        name: 'itemview',
        path: '/equipment/item-view',
        component: () => import('@/views/Equipment/Virtualmaterial/virtualview.vue'),
        meta: {
          title: 'lang.cpq.menu.virtualMaterialApply', // 虚拟物料查看
          breadcrumb: [{ title: 'lang.cpq.menu.virtual.material', path: '/equipment/virtual-material' }, { title: 'lang.cpq.menu.virtualMaterialView' }],
        },
      },
      // 虚拟物料审批
      {
        name: 'itemproval',
        path: '/equipment/virtual-material/approval',
        component: () => import('@/views/Equipment/Virtualitemapproval/List.vue'),
        meta: {
          title: 'lang.cpq.menu.virtual.material.approval', // 虚拟物料审批
          breadcrumb: [{ title: 'lang.cpq.menu.virtual.material.approval', path: '/equipment/virtual-material/approval' }],
        },
      },
      // 虚拟物料审批页面
      {
        name: 'itemdetail',
        path: '/equipment/item-detail',
        component: () => import('@/views/Equipment/Virtualmaterial/Detail.vue'),
        meta: {
          title: 'lang.cpq.menu.virtual.material.approval', // 虚拟物料审批
          breadcrumb: [{ title: 'lang.cpq.menu.virtual.material.approval', path: '/equipment/virtual-material/approval' }, { title: 'lang.cpq.menu.virtual.material.approval' }],
        },
      },
      // 虚拟物料管理
      {
        name: 'virtualmanagement',
        path: '/equipment/virtual-material/price',
        component: () => import('@/views/Equipment/virtualmanagement/List.vue'),
        meta: {
          title: 'lang.cpq.menu.virtual.material.price', // 虚拟物料管理
          breadcrumb: [{ title: 'lang.cpq.menu.virtual.material.price', path: '/equipment/virtual-material/approval' }],
        },
      },
      // 添加虚拟物料
      {
        name: 'addavirtualitem',
        path: '/equipment/add-avirtualitem',
        component: () => import('@/views/Equipment/virtualmanagement/Addavirtualitem.vue'),
      },
      // 物料定价系数维护
      {
        name: 'itemPricingFactor',
        path: 'item-pricing-factor',
        component: () => import('@/views/Equipment/Virtualmaterial/Itempricingfactor.vue'),
        meta: {
          title: 'lang.cpq.menu.product.pricing.factor', // 虚拟物料管理
          breadcrumb: [{ title: 'lang.cpq.menu.product.pricing.factor', path: '/equipment/item-pricing-factor' }],
        },
      },
      // 外购物料列表
      {
        name: 'purchaseMaterialList',
        path: 'purchase-material/list',
        component: () => import('@/views/Equipment/PurchaseMaterial/List.vue'),
        meta: {
          title: 'lang.cpq.label.purchasedMaterialsApplication', // 外购物料
          breadcrumb: [{ title: 'lang.cpq.label.purchasedMaterialsApplication', path: '/equipment/purchase-material/list' }],
        },
      },
      // 外购物料审批列表
      {
        name: 'purchaseMaterialApprovalList',
        path: 'purchase-material/approval-list',
        component: () => import('@/views/Equipment/PurchaseMaterial/ApprovalList.vue'),
        meta: {
          title: 'lang.cpq.label.purchasedMaterialsApproval', // 外购物料审批
          breadcrumb: [{ title: 'lang.cpq.label.purchasedMaterialsApproval', path: '/equipment/purchase-material/approval-list' }],
        },
      },
      // 外购物料创建
      {
        name: 'purchaseMaterialCreate',
        path: 'purchase-material/create',
        component: () => import('@/views/Equipment/PurchaseMaterial/Create.vue'),
        meta: {
          title: 'lang.cpq.equipment.label.shoppingmaterials', // 外购物料
          breadcrumb: [{
            title: 'lang.cpq.label.purchasedMaterialsApplication',
            path: '/equipment/purchase-material/list',
          }, { title: 'lang.cpq.equipment.label.shoppingmaterials' }],
        },
      },
      // erp确认
      {
        name: 'purchaseMaterialErpConfirm',
        path: 'purchase-material/erp-confirm',
        component: () => import('@/views/Equipment/PurchaseMaterial/ErpConfirm.vue'),
        meta: {
          title: 'lang.cpq.label.erpConfirm', // erp确认
        },
      },
      // 定价待办查询
      {
        path: 'price-pending-search',
        name: 'pricePendingSearch',
        component: () => import('@/views/Base/Iframe.vue'),
        meta: {
          title: 'lang.cpq.label.pricePendingSearch',
        },
      },
    ],
  },
];
