export default [
  {
    path: 'implement-work-hour-measure',
    name: 'implementWorkHourMeasure',
    component: () => import('@/views/Base/Base'),
    meta: {
      title: 'lang.cpq.label.implementWorkHourMeasure', // 实施工时测算
      icon: 'apply',
    },
    children: [
      // 列表 - CPQ
      {
        path: 'list',
        name: 'implementWorkHourMeasureList',
        component: () => import('@/views/ImplementWorkHourMeasure/List.vue'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.label.implementWorkHourMeasure',
            path: '/implement-work-hour-measure/list',
          }, {
            title: 'lang.cpq.label.implementWorkHourMeasureList',
          }],
        },
      },
      // 列表 - GPM
      {
        path: 'gpm-list',
        name: 'implementWorkHourMeasureGpmList',
        component: () => import('@/views/ImplementWorkHourMeasure/List.vue'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.label.implementWorkHourMeasure',
            path: '/implement-work-hour-measure/gpm-list',
          }, {
            title: 'lang.cpq.label.implementWorkHourMeasureList',
          }],
        },
      },
      // 新增
      {
        path: 'create',
        name: 'implementWorkHourMeasureCreate',
        component: () => import('@/views/ImplementWorkHourMeasure/Create.vue'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.label.implementWorkHourMeasure',
            path: '/implement-work-hour-measure/list',
          }, {
            title: 'lang.cpq.label.createImplementWorkHourMeasure',
          }],
        },
      },
      // 新增 - GPM
      {
        path: 'gpm-create',
        name: 'implementWorkHourMeasureGpmCreate',
        component: () => import('@/views/ImplementWorkHourMeasure/Create.vue'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.label.implementWorkHourMeasure',
            path: '/implement-work-hour-measure/gpm-list',
          }, {
            title: 'lang.cpq.label.createImplementWorkHourMeasure',
          }],
        },
      },
    ],
  },
];
